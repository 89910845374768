import Grid from "@mui/material/Grid";
import { useLocation } from 'react-router-dom';
import { getCompanies } from "../../services/Company.service";
//import courtsData from '../../assets/data.json';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './Results.css';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import * as React from 'react';

function Results({ route, navigation }) {

    const { t } = useTranslation();
    const location = useLocation();

    const data = location.state;
    const courtType = data.courtType;

    const [courts, setCourts] = React.useState([]);

    function loadCourts() {
        console.log("hello1")
        getCompanies(data).then(results => {
            const courtsData = results.data;
            console.log("hello", courtsData);
            if (courtsData) {
                setCourts(courtsData.filter(company => company.sport.name.includes(courtType)));
            }
            console.log(courtsData.filter(company => {
                console.log(courtType, company.sport)
                console.log(company.sport.name.includes(courtType))
        }));

            console.log(courts);
        })
    }

    React.useEffect(() => {
        loadCourts(); // this will fire only on first render
    }, []);
    return (
        <div className="court-list">
          {courts.map((item, i) => (     
            <Card variant="outlined" className="court">
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" sx={{fontWeight: 'bold', color: "#44ad2f"}}>
                        {item.name}
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {item.address}, {item.area}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {item.city}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {item.contact_number}
                    </Typography>
                    <Link href={item.url} target="_blank" sx={{ fontSize: '0.6em', display: 'flex', justifyContent: 'center'}}>{item.url}</Link>
                    {/* <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    <b>{t('Format')}</b> {item.fieldType}
                    </Typography> */}

                </CardContent>
           </Card>
         ))}
        </div>
    );
}


export default Results;