import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import LocationOn from '@mui/icons-material/LocationOn';
import TextField from '@mui/material/TextField';
import courtsData from '../../assets/data.json';
import * as React from 'react';

function AutocompleteLocation(props) {

    const [value, setValue] = React.useState(null);

    const filter = createFilterOptions();
    const [courtType, setCourtType] = React.useState(props.info.courtType);

    return (
        <Autocomplete
        value={value}
        onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
            setValue({
                title: newValue,
            });
            } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
                title: newValue.inputValue,
            });
            } else {
            setValue(newValue);
            }
        }}
        filterOptions={(options, params) => {
            const filteredCourts = options.filter(court => court.type.includes(courtType));
            console.log(filteredCourts)
            const filtered = filter(filteredCourts, params);
            console.log(courtType);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.name);
            if (inputValue !== '' && !isExisting) {
                // console.log('does not exist')
            }

            return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={courtsData}
        getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
                return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
                return option.inputValue;
            }
            // Regular option
            return option.name;
        }}
        renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
            <li key={key} {...optionProps}>
                {option.name}
            </li>
            );
        }}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
            <TextField {...params} 
            InputProps={{
                ...params.InputProps,
                startAdornment: (
                <InputAdornment position="start">
                    <LocationOn />
                </InputAdornment>
                )
            }}
            label="Type location or club name" />
        )}
      />
    )
}

export default AutocompleteLocation;