import logo from './logo.svg';
import './App.css';
import './styles/_global.css';
import HomePage from './pages/HomePage';
import Navbar from './components/Navbar/Navbar.jsx';
import { ThemeProvider } from '@mui/material';
import { theme } from './themes/theme.js';
import { Route,Routes } from 'react-router-dom';
import Results from './components/Results/Results.jsx';
import ContactPage from './pages/ContactPage.jsx';
import React, { useState } from 'react';

function App() {

    const [courtsData, setCourtsData] = useState('');

    // Callback function to receive data from the child component
    const handleCourtsdData = (data) => {
    setCourtsData(data);
    console.log('Data received from child:', data);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <div class="app-container">
            <Navbar onCourtsData={handleCourtsdData} />
            <Routes>        
              <Route path="/"  element={<HomePage/>} />
              <Route path="/home"  element={<HomePage/>} />
              <Route path="/contact"  element={<ContactPage/>} />
              <Route path="/results"  element={<Results/>} />
            </Routes>
          </div>
        </header>
      </div>
    </ThemeProvider>
  );
}

export default App;
