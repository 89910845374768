import { Paper } from "@mui/material";

function ContactPage() {

    return (
        <div>
            <Paper sx={{backgroundColor: 'primary.contrastText', color: 'secondary.contrastText', marginTop: '1rem', 
            }}>
                Contact Us
            </Paper>
        </div>
        )
}

export default ContactPage;