import { createTheme } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';


export const theme = createTheme({
    palette: {
        primary: {
          light: '#757ce8',
          //main: '#57e83a',
          main: '#57d93d',
          dark: '#41ba29',
          contrastText: '#fff',
        },
        secondary: {
          light: '#5c5e63',
          main: '#393a3d',
          dark: '#18181a',
          contrastText: '#000',
        }
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'system-ui',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
      ].join(','),
    }
});
