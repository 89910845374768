import * as React from 'react';
import './Navbar.css'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import Popover from '@mui/material/Popover';
import AutocompleteLocation from '../AutocompleteLocation/AutocompleteLocation';
import { useNavigate } from "react-router-dom";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import HomeIcon from '@mui/icons-material/Home';
import NewspaperIcon from '@mui/icons-material/Newspaper';


function Navbar({ onCourtsData }) {
  const { t, i18n } = useTranslation();
  const courts = ["Padel", "Tennis", "Football", "Basketball"];
  const [courtType, setCourtType] = React.useState("padel");
  
  const pages = [{title: t('Home'), link: 'Home', icon: 'home'}, {title: t('News'),
    link: 'News', icon: 'news'}, {title: t('Contact'), link: 'Contact', icon: 'contact'}];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lang, setLang] = React.useState(0);

  function getIcon(name, props) {
    if (name === 'home') {
      return (
        <HomeIcon />
      );
    } else if (name === 'contact')  {
      return (
        <ContactPageIcon />
      );
    } else if (name === 'news') {
      return (
        <NewspaperIcon />
      );
    }
  }

  const handleLangChange = (event) => {
    console.log(event.target.value);
    setLang(event.target.value);
    if (event.target.value === 0) {
      console.log('came ')
      i18n.changeLanguage('gr');
    } else {
      i18n.changeLanguage('en');
    }
  };


  const handleSearch = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleCourtTypeChange = (event) => {
    setCourtType(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  let navigate = useNavigate(); 

  const handleSearchCourt = () => {
    handleClose();
    let path = `/results`; 
    navigate(path, { state: {courtType: courtType}});
    onCourtsData();
}

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to={`/home`} className="navbar-text" >
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              ml: 1,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'helvetica',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            
          Athlify
          </Typography>
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem className="navbar-text" key={page.title} onClick={handleCloseNavMenu}>
                  <Link to={`/${page.link}`}><Typography textAlign="center">{page.title}
                    </Typography></Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Link to={`/home`} className="navbar-text" >
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'helvetica',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Athlify
          </Typography>
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.title}
                onClick={handleCloseNavMenu}
                className="navbar-text"
              >
                {getIcon(page.icon)}<Link to={`/${page.link}`} className="page-link">{page.title}</Link>
              </Button>
            ))}
                <Button variant="text" startIcon={<SearchIcon />} size="large" onClick={handleSearch}
                sx={{bgcolor: 'primary.main', color: 'primary.contrastText',
                  marginLeft: '0.5rem',
                    ':hover': {
                        bgcolor: 'primary.dark',
                        color: 'primary.contrastText',
                    }
                }}>
                {t('Search')}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Container>
                  <Box sx={{padding: '1rem'}}>
                  <label>{t('selectCourtType')}</label>
                  <Box>
                      <Select
                      name="active"
                      value={courtType}
                      labelId="active-label"
                      id="active"
                      onChange={handleCourtTypeChange}
                      sx={{ width: '500px', backgroundColor: '#ffffff', marginTop: '0.5rem', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                  >
                      <MenuItem value="padel">{courts[0]}</MenuItem>
                      <MenuItem value="tennis">{courts[1]}</MenuItem>
                      <MenuItem value="football">{courts[2]}</MenuItem>
                      <MenuItem value="basketball">{courts[3]}</MenuItem>
                  </Select>
                  </Box>
                  </Box>

                  <AutocompleteLocation info={{courtType: courtType}} />
                  <Button startIcon={<SearchIcon />} size="large" onClick={handleSearchCourt}
                    sx={{bgcolor: 'primary.main', color: 'primary.contrastText', margin: '1rem',
                        ':hover': {
                            bgcolor: 'primary.dark',
                            color: 'primary.contrastText',
                        }
                    }}>
                    {t('Search')}
                </Button>
                </Container>
              </Popover>
          </Box>

        <Box sx={{ flexGrow: 0 }}>
          <IconButton onClick={() => handleLangChange({ target: { value: 0 } })}>
            <img src={require('../../assets/greece.png')} alt="Greek" style={{ width: '24px', height: '24px' }} />
          </IconButton>
          <IconButton onClick={() => handleLangChange({ target: { value: 1 } })}>
            <img src={require('../../assets/united-kingdom.png')} alt="English" style={{ width: '24px', height: '24px' }} />
          </IconButton>
        </Box>

           {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
