import Searchbar from "../components/Searchbar/SearchBar";
import React, { useState } from 'react';


function HomePage() {
  const [courtsData, setCourtsData] = useState('');

   // Callback function to receive data from the child component
   const handleCourtsdData = (data) => {
    setCourtsData(data);
    console.log('Data received from child:', data);
  };

    return (
        <Searchbar onCourtsData={handleCourtsdData} />
      );
}

export default HomePage;