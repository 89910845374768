import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import './Searchbar.css';
import keyData from '../../config/google_maps_api_key.json';
//import { GoogleComponent } from 'react-google-location';
import InputAdornment from '@mui/material/InputAdornment';
import LocationOn from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import courtsData from '../../assets/data.json';
import { useNavigate } from "react-router-dom";

// const API_KEY = keyData.key;

// render

function Searchbar({ onCourtsData }) {
    const { t } = useTranslation();
    const courts = ["Padel", "Tennis", "Football", "Basketball"];
    const [courtType, setCourtType] = React.useState("padel");

    const [state, setState] = React.useState({place : null});

    const [value, setValue] = React.useState(null);

    const filter = createFilterOptions();

    const handleCourtTypeChange = (event) => {
        setCourtType(event.target.value);
    };

    let navigate = useNavigate(); 

    const handleSearch = () => {
        let path = `/results`; 
        navigate(path, { state: {courtType: courtType}});
        onCourtsData();
    }


    return (
    <Box className="home-container">
        <h1>{t('bookGame')}</h1>
        <Container 
            className="search-container" 
            sx={{ 
                width: 'fit-content',   // Let container shrink to fit
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                gap: '1.5rem', 
                backgroundColor: '#ffffff',
                // Use flex-wrap if needed for smaller screens
                flexWrap: 'wrap',
                margin: '0 auto'        // Center horizontally
            }}
            >
            <SportsTennisIcon color="primary" />
            <Select
                name="active"
                value={courtType}
                onChange={handleCourtTypeChange}
                sx={{ 
                backgroundColor: '#ffffff', 
                boxShadow: 'none', 
                '.MuiOutlinedInput-notchedOutline': { border: 0 } 
                }}
            >
                <MenuItem value="padel">Padel</MenuItem>
                <MenuItem value="tennis">Tennis</MenuItem>
                <MenuItem value="football">Football</MenuItem>
                <MenuItem value="basketball">Basketball</MenuItem>
            </Select>

            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    setValue({ title: newValue });
                } else if (newValue && newValue.inputValue) {
                    setValue({ title: newValue.inputValue });
                } else {
                    setValue(newValue);
                }
                }}
                filterOptions={(options, params) => {
                const filteredCourts = options.filter(court => court.type.includes(courtType));
                const filtered = createFilterOptions()(filteredCourts, params);
                return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={courtsData}
                getOptionLabel={(option) => option.inputValue || option.name || option}
                renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return <li key={key} {...optionProps}>{option.name}</li>;
                }}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                <TextField 
                    {...params}
                    label="Type location or club name"
                    InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                        <InputAdornment position="start">
                        <LocationOn />
                        </InputAdornment>
                    )
                    }}
                />
                )}
            />

            <Button 
                startIcon={<SearchIcon />} 
                size="large" 
                onClick={handleSearch}
                sx={{
                bgcolor: 'primary.main', 
                color: 'primary.contrastText',
                ':hover': { bgcolor: 'primary.dark', color: 'primary.contrastText' }
                }}
            >
                {t('Search')}
            </Button>
        </Container>
    </Box>
    )
}

export default Searchbar;