import React, { useState, useEffect } from 'react';
import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;


const getCompanies = async () => {

    try {
   
    const post = await axios.get(`${URL}/user/companies`,
        {
            headers: {
              "Cache-Control": "no-cache",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            }
          }
    );
   
    return post;
   
     } catch (e) {
   
    const msg = e?.response?.error.message ?? e?.message ?? 'Unknown Error';
   
    console.error(msg);
   
   
   
    return false;
   
     }
   
   };

export { getCompanies }
